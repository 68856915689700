export default {
  register: "สมัครสมาชิก",
  phone: "เบอร์โทรศัพท์",
  welcome: "ยินดีต้อนรับสู่ระบบ",
  agreeTermAndCondition: "ยอมรับเงื่อนไขการให้บริการ",
  back: "ย้อนกลับ",
  termAndCondition: "เงื่อนไขการใช้บริการ",
  cancel: "ยกเลิก",
  confirm: "ตกลง",
  required: "กรุณากรอกข้อมูล",
  zipcode: "รหัสไปรษณีย์",
  amphur: "แขวง / ตำบล",
  district: "เขต / อำเภอ",
  province: "จังหวัด",
  save: "บันทึก",
  close: "ปิด",
  name: "ชื่อ",
  surname: "นามสกุล",
  birthDay: "วันเกิด",
  gender: "เพศ",
  educationLevel: "ระดับการศึกษา",
  jobLevel: "ระดับตำแหน่งงาน",
  jobPosition: "ตำแหน่งงาน",
  department: "แผนก",
  company: "บริษัท",
  address: "ที่อยู่",
  enterRegister: "ลงทะเบียน",
  management: "Management",
  manager: "Manager",
  supervisor: "Supervisor",
  officer: "Officer / Staff",
  worker: "Worker / Operator",
  doctorate: "ปริญญาเอก",
  masterDegree: "ปริญญาโท",
  bachelorDegree: "ปริญญาตรี",
  highVocational: "ปวส.",
  technicalVocational: "ปวท.",
  vocational: "ปวช.",
  highSchool: "มัธยมปลาย",
  secondarySchool: "มัธยมต้น",
  primarySchool: "ประถมศึกษา",
  lowerPrimary: "ต่ำกว่าประถมศึกษา",
  male: "ชาย",
  female: "หญิง",
  other: "อื่นๆ",
  profile: "ข้อมูลส่วนตัว",
  id: "รหัสประจำตัว",
  nameSurname: "ชื่อ-นามสกุล",
  sendRefCodeOtp: "ส่ง OTP รหัส",
  requestOtpAgain: "ขอรหัสอีกครั้ง",
  edit: "แก้ไข",
  add: "เพิ่ม",
  addressSendReward: "ที่อยู่จัดส่งของรางวัล",
  addressDetail: "รายละเอียดที่อยู่",
  selectDefautAddress: "เลือกเป็นที่อยู่ตั้งต้น",
  addressEdit: "แก้ไขที่อยู่",
  addressAdd: "เพิ่มที่อยู่",
  defaultValue: "ค่าเริ่มต้น",
  hello: "สวัสดี คุณ",
  coinTotal: "จำนวนเหรียญของคุณ",
  homePage: "หน้าแรก",
  activityPage: "ร่วมกิจกรรม",
  redemptPage: "แลกของรางวัล",
  dealPage: "ดีลพิเศษสำหรับคุณ",
  invitePage: "เชิญเพื่อน",
  addressPage: "ที่อยู่จัดส่งของรางวัล",
  favoritePage: "รายการโปรด",
  cancelShipment: "สถานะยกเลิกการจัดส่ง",
  couponPage: "คูปองของฉัน",
  statusDeliveryPage: "เช็คสถานะจัดส่งของรางวัล",
  logout: "ออกจากระบบ",

  categoryAll: "ทั้งหมด",
  categoryRecommend: "แนะนำ",
  categoryFood: "อาหาร",
  categoryElectrical: "เครื่องใช้ไฟฟ้า",
  categoryAppliance: "เครื่องใช้ในบ้าน",
  categoryOther: "อื่นๆ",

  dealBrand: "ดีลแบรนด์ดังสุดปัง!!",
  deal: "ดีลเลย!",
  totalValue: "จำนวนที่เหลือ",
  detail: "รายละเอียด",
  youWant: "คุณต้องการ",
  delete: "ลบ",
  favoriteText: "ในรายการโปรด",
  waitingLoad: "กรุณารอสักครู่",
  expireTime: "หมดเขตแล้ว",
  coin: "เหรียญ",
  surveyRegister: "แบบสอบถามการลงทะเบียน",
  later: "ภายหลัง",
  redeem: "แลกเพียง",
  confirmRedeem: "ยืนยันการแลกเหรียญ",
  confirmAdd: "คุณต้องการเพิ่ม",
  inYourFav: "ในรายการโปรด",
  confirmDelete: "คุณต้องการลบ",
  fromYourFav: "ออกจาก รายการโปรด ",
  balance: "จำนวนคงเหลือ",
  inviteFrindDetail: "เพียงกดชวนเพื่อนมาใช้แอพ เมื่อเพื่อนลงทะเบียนสำเร็จ ",
  received: "ได้รับ",
  inviteFriend: "ชวนเพื่อน",
  inviteFriendPhoneText: "กรอกเบอร์โทรศัพท์ที่ต้องการเชิญเพื่อน",
  fillPhone: "กรอกเบอร์โทรศัพท์เพื่อน",
  historyRedeem: "ประวัติการสะสมเหรียญ",
  transferCoin : "โอนเหรียญ",
  coinReceived: "เหรียญที่ได้รับ",
  coinUsed: "เหรียญที่ใช้ไป",
  activityAll: "กิจกรรมทั้งหมด",
  expireDate: "หมดเขตวันที่",
  note: "หมายเหตุ",
  accessCamera: "กรุณาตั้งค่ายินยอมในการเข้าถึงกล้อง",
  checkIn: "เช็คอิน",
  activityExpireDate: "วันสิ้นสุดกิจกรรม",
  collectCoin: "สะสมเหรียญ",

  activityJoin: "เข้าร่วมกิจกรรม",
  activityQr: "แสกน QR CODE",
  activitySurvey: "เข้าร่วมกิจกรรม",
  activityUpload: "ส่งผลลัพธ์กิจกรรม",
  activityHeartToHeart: "ส่งข้อความ",
  activityScanQr: "SCAN QR",

  sendSurvey: "ส่งแบบทดสอบ",
  uploadFile: "Upload File",
  sizeFile: "ขนาดไฟล์ไม่เกิน 10 MB",
  activityStatusJoin: "กิจกรรมที่กำลังเข้าร่วม",
  activityStatusWait: "กิจกรรมที่รออนุมัติ",
  activityStatusSuccess: "กิจกรรมที่สำเร็จ",
  activityStatusReject: "กิจกรรมที่ไม่สำเร็จ",
  registerToReceive: "ลงทะเบียนเพื่อรับสิทธิ์",
  confirmUseCoupon: "ยืนยันการใช้คูปอง",
  noteUseCoupon:
    "แนะนำให้กดสิทธิ์เมื่ออยู่ที่ร้านค้าแล้ว เพื่อป้องกันไม่ให้เสียสิทธิ์",
  noteAlertUseCoupon: "รหัสจะถือว่าใช้แล้วทันที หลังกดรับสิทธิ์",
  codeDiscount: "โค้ดส่วนลด",
  errorQr: "กรุณาติดต่อเจ้าหน้าที่ผู้ดูแลระบบ",
  cancelRedemption: "คุณต้องการยกเลิก การทำรายการ? ",
  cancelRedemptionNote: "โดยจะไม่ได้รับเหรียญคืนในการยกเลิก",
  statusDelivery: "สถานะจัดส่งของรางวัล",
  statusSendIn7Day: "จัดส่งภายใน 7 วัน",
  statusDeliverySuccess: "จัดส่งสำเร็จแล้ว",
  statusDeliveryCancel:"ยกเลิกการจัดส่ง",
  trackingNumber: "หมายเลขติดตามพัสดุ",
  receiveReward: "รับของรางวัล",
  copyText: "คัดลอกแล้ว",
  confirmReceiveReward: "ยืนยันการรับของรางวัล",
  activitySuggest: "กิจกรรมแนะนำ",
  pageNotFound: "Not Found Page",
  goBackPage: "Go to Back Page",
  goToLogin: "Go To Login Page",
  changeLanguage: "เปลี่ยนภาษา",
  all: "ทั้งหมด",
  join: "กำลังเข้าร่วม",
  success: "สำเร็จ",
  failed: "ไม่สำเร็จ",
  waiting: "รออนุมัติ",
  pending: "ยังไม่จัดส่ง",
  sent: "จัดส่งแล้ว",
  delivered: "จัดส่งสำเร็จ",
  textRequireAgain: "ขอรหัส otp ใหม่อีกครั้งภายใน : ",
  welcomeActivity: "ยินดีต้อนรับการร่วมสนุก",
  errQr: "รหัส Qr Code ไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
  requireExam: "กรุณากรอกแบบสอบถามให้ครบ",
  uploadSuccess: "ส่งผลลัพธ์เรียบร้อยแล้ว",
  uploadWaiting: "จะดำเนินการตรวจสอบภายใน 7 วัน",
  errDupActivity: "คุณได้ทำกิจกรรมนี้แล้ว กรุณาเลือกกิจกรรมอื่น",
  errExpireActivity: "ขออภัยค่ะ กิจกรรมหมดเวลาไปเรียบร้อยแล้ว",
  errFileUpload: "ขนาดไฟล์ไม่ควรเกิน 10 mb",
  expireDateUse: "ใช้ได้ถึง",
  expires: "Expires",
  registerPage: "ไปหน้าลงทะเบียน",
  shareActivity: "แชร์กิจกรรม",
  goToDeal: "ดูดีลทั้งหมด",
  goToMission: "ดูกิจกรรมทั้งหมด",
  menuActivity: "กิจกรรม",
  menuDeal: "ดีลสุดพิเศษ",
  menuRedeem: "แลกของรางวัล",
  menuCoupon: "คูปองของฉัน",
  redeemPoint: "แลก",
  home: "หน้าหลัก",
  activity: "กิจกรรม",
  tracking: "ติดตาม",
  account: "บัญชี",
  reward: "รางวัล",
  rewardSoHot: "รางวัลสุดฮอต",
  hotMission: "กิจกรรม กำลังมาแรง",
  recommendMission: "กิจกรรม แนะนำ",
  doneDetailMission: "รายละเอียดการเข้าร่วมกิจกรรม",
  detailMission: "รายละเอียดกิจกรรม",
  newActivityUpload: "ส่งข้อมูลอีกครั้ง",
  errNotTimeForActivity: "ยังไม่ถึงเวลาเล่นกิจกรรม",
  news: "ข่าวสาร",

  favoriteCategory: "รายการโปรด",
  deviceCategory: "เครื่องใช้ไฟฟ้า",
  accessoryCategory: "อุปกรณ์อิเล็กทรอนิกส์",
  healthCategory: "สุขภาพและความงาม",
  foodCategory: "อาหารและเครื่องดื่ม",
  babyCategory: "เด็กและของเล่น",
  petCategory: "สัตว์เลี้ยง",
  homeCategory: "บ้านและไลฟ์สไตล์",
  menCategory: "แฟชั่นผู้ชาย",
  womenCategory: "แฟชั่นผู้หญิง",
  sportCategory: "กีฬา",
  travelCategory: "ท่องเที่ยว",

  historyCoupon: "ประวัติการใช้คูปอง",
  missionLimitReached: "สิทธิเต็มแล้ว",
  rewardLimitReached: "สิทธิเต็มแล้ว",
  missionComingSoon: "เร็วๆนี้",
  missionTimeCannotJoin: "อยู่นอกช่วงเวลากิจกรรม",
  couponExpired: "คูปองหมดอายุ",
  conditionCoin: "ตามเงื่อนไข",
  agree: "ยินยอม",
  disagree: "ไม่ยินยอม",

  createBlog:"เขียนบทความ",
  editBlog:"แก้ไขบทความ",

};
