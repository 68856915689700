import liff from "@line/liff";
import Axios from "@/config/customer-service";
import store from "@/store";
import AxiosBackend from "@/config/backend-service";
import i18n from "../plugins/i18n";

async function getSiteTemplate() {
  return new Promise((resolve) => {
    AxiosBackend.get("site/getSiteTemplate", {
      params: {
        url: " https://" + window.location.hostname,
      },
    })
      .then(({ data }) => {
        if (data.status == "success") {
          store.commit("defaultSystem/SET_SITE", data.data);
          i18n.locale = "th";
          resolve(data.data.liff_id);
        }
      })
      .catch((err) => {
        throw err;
      });
  });
}
async function setToken(accessToken) {
  return new Promise((resolve) => {
    localStorage.setItem("token", accessToken);
    resolve();
  });
}

async function setProfile(profile) {
  return new Promise((resolve) => {
    store.commit("defaultSystem/SET_LINE_USER", profile);
    resolve();
  });
}

export const checkLineUserBypass = async (to, form, next) => {
  try {
    let liff_id = await getSiteTemplate();
    const vuex = JSON.parse(localStorage.getItem("vuex"));
    const isUseLine = vuex.defaultSystem.site.is_use_line;
    const isLoginByPass = vuex.defaultSystem.site.is_login_bypass;
    const token = localStorage.getItem("token");
    store.commit("friend/SET_INVITE", to.query.invite);


    if (isLoginByPass !== "N") {
      store.commit("defaultSystem/SET_USER", { role: "Customer" });
      if (token) {
        return next({ name: "main" });
      } else {
        return next({ name: "login-bypass" });
      }
    }
    //redirect to login phone
    if (isUseLine !== "Y") {
      store.commit("defaultSystem/SET_USER", { role: "Customer" });
      return next({ name: "checknumberbyotp" });
    }
    let checkTimeOut = true;
    setTimeout(() => {
      if (checkTimeOut) {
        alert("กรุณาปิดหน้าต่างเว็บไซต์ และเข้าใหม่อีกครั้ง")
      }
    }, 5000);
    await liff.init({ liffId: liff_id, withLoginOnExternalBrowser: true, }).catch((err) => {
      throw err;
    });
    checkTimeOut = false;

    if (!liff.isLoggedIn()) {
      // query invite
      if (to.query.invite) {
        store.commit("friend/SET_INVITE", to.query.invite);
      }
      liff.login({
        // redirectUri: window.location.href + `?cache=${(new Date()).getTime()}`,
        redirectUri: window.location.href,
      });
    }

    store.commit("defaultSystem/SET_USER", { role: "Customer" });

    const accessToken = await liff.getAccessToken();

    await setToken(accessToken);

    const profile = await liff.getProfile();

    await setToken(accessToken);
    await setProfile(profile);

    if (profile) {
      Axios.get("checkUserIsRegisteredLine", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => {
          if (res.data.status == "registered") {
            localStorage.setItem("token", res.data.data.access_token);

            setTimeout(() => {
              next({ name: "main" });
            }, 1000);
          }
          if (res.data.status == "not_registered")
            return next({ name: "checknumberbyline" });
          if (res.data.status == "unauthorized")
            return next({ name: "checknumberbyline" });
        })
        .catch((err) => {
          console.log("err :>> ", err);
        });
    }
  } catch (error) {
    console.log("error :>> ", error);
  }
};
